<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2924 7.44148L8.16572 13.5681C7.41516 14.3187 6.39718 14.7404 5.33572 14.7404C4.27426 14.7404 3.25628 14.3187 2.50572 13.5681C1.75516 12.8176 1.3335 11.7996 1.3335 10.7381C1.3335 9.67669 1.75516 8.65871 2.50572 7.90815L8.63239 1.78148C9.13276 1.28111 9.81142 1 10.5191 1C11.2267 1 11.9053 1.28111 12.4057 1.78148C12.9061 2.28186 13.1872 2.96051 13.1872 3.66815C13.1872 4.37579 12.9061 5.05444 12.4057 5.55482L6.27239 11.6815C6.0222 11.9317 5.68287 12.0722 5.32905 12.0722C4.97524 12.0722 4.63591 11.9317 4.38572 11.6815C4.13553 11.4313 3.99498 11.092 3.99498 10.7381C3.99498 10.3843 4.13553 10.045 4.38572 9.79482L10.0457 4.14148"
      stroke="currentColor"
      stroke-width="1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
