import { BaseService } from "./base.service";
import { ErrorWrapper, ResponseWrapper } from "./util";
import axios from "axios";

export class ReplyService extends BaseService {
  static async listReplies() {
    try {
      const response = await this.request({ auth: true }).get(
        "/messagetemplate"
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getReply(id) {
    try {
      const response = await this.request({ auth: true }).get(
        `/messagetemplate/${id}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async searchReplies(query) {
    try {
      let encodedQuery = encodeURIComponent(query);
      const response = await this.request({ auth: true }).get(
        `/messagetemplate/search?contains=${encodedQuery}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async addTemplate(data) {
    try {
      const response = await this.request({ auth: true }).post(
        "/messagetemplate",
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updateMessageTemplate(id, data) {
    try {
      const response = await this.request({ auth: true }).put(
        `/messagetemplate/${id}`,
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async deleteMessageTemplate(id) {
    try {
      const response = await this.request({ auth: true }).delete(
        `/messagetemplate/${id}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async addTemplateWithAttachment(data) {
    try {
      const response = await this.request({ auth: true }).post(
        "/messagetemplate/attachment",
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async addAttachmentToTemplate(id, data) {
    try {
      const response = await this.request({ auth: true }).put(
        `/messagetemplate/${id}/attachment`,
        data
      );
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async removeAttachment(replyid, attachmentid) {
    try {
      const response = await this.request({ auth: true }).delete(
        `/messagetemplate/${replyid}/attachment/${attachmentid}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getAttachment(url) {
    try {
      const response = await axios.get(url, { responseType: "blob" });
      return response;
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  // Categories

  static async listReplyCategory() {
    try {
      const response = await this.request({ auth: true }).get(
        "/messagetemplatecategory"
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getReplyCategory(id) {
    try {
      const response = await this.request({ auth: true }).get(
        `/messagetemplatecategory/${id}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async addReplyCategory(data) {
    try {
      const response = await this.request({ auth: true }).post(
        "/messagetemplatecategory",
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updateReplyCategory(id, data) {
    try {
      const response = await this.request({ auth: true }).put(
        `/messagetemplatecategory/${id}`,
        data
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      return new ErrorWrapper(error);
    }
  }

  static async deleteReplyCategory(id) {
    try {
      const response = await this.request({ auth: true }).delete(
        `/messagetemplatecategory/${id}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      return new ErrorWrapper(error);
    }
  }

  static async searchReplyByCategory(categoryid) {
    try {
      const response = await this.request({ auth: true }).get(
        `/messagetemplate?messagetemplatecategoryid=${categoryid}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      return new ErrorWrapper(error);
    }
  }

  static async getMergeTags() {
    try {
      const response = await this.request({ auth: true }).get(
        "/messagetemplatetag"
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      return new ErrorWrapper(error);
    }
  }
}
